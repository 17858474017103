import solIcon from '../assets/image/sol.svg'
import aogoIcon from '../assets/image/aogoicon.svg'
import ethIcon from '../assets/image/eth.svg'
import yesIcon from '../assets/image/yes.svg'
import noIcon from '../assets/image/no.svg'
import './index.scss'
const Item = ({data}) => {
    return (
        <div className="best-item flex flex-column">
              <img src={data.icon} alt="" className='avatar' />
              <div className="cf fz-20">{data.title}</div>
              <div className={"tips fz-14 "+(data.winner?'active':'')}>{data.winner ? 'WINNER':'RUNNER UP'}</div>
              <div className="cf fwb fz-40 m-t-10">${data.token}</div>
              <div className="choice">GOOD CHOICE</div>
              {
                data.texts.map((item, index) => {
                    return (
                        <div key={index} className="yes-no cf fz-14 flex flex-start gap-6 m-t-8 tl">
                <img src={item.icon=='yes'?yesIcon:noIcon} alt="" className='m-t-3' />
                {item.content}
              </div>
                    )
                })
              }
              <div className="m-t-25"></div>
              <a href="https://jup.ag/swap/SOL-Aogo_2PCG4XHNTndUSCNEaxzP4uKJUQDfAh6UJKhRoexN1oFN" target="_blank" className='normal-a'>
              <div className="pointer buy-btn fz-16 lh-42 bdr-21 fwb ">BUY $AOGO</div>
              </a>
              

        </div>
    )
}

const list = [
    {
        icon: solIcon,
        winner: false,
        title: 'SOLANA',
        token: 'SOL',
        texts : [
            {
                icon: 'yes',
                content: '4272 transactions/second'
            },
            {
                icon: 'yes',
                content: '240+ billion transactions'
            },
            {
                icon: 'yes',
                content: 'chain used for $AOGO'
            },
            {
                icon: 'no',
                content: 'not a dog'
            },{
                icon: 'no',
                content: 'no hat'
            }

        ]
    },
    {
        icon: aogoIcon,
        winner: true,
        title: 'SOLANA',
        token: 'AOGO',
        texts : [
            {
                icon: 'yes',
                content: "AOGO isn't just a cryptocurrency; it's a symbol of progress, for futuristic transactions, a beacon for those who think ahead. It's clear that the future belongs to those who embrace innovations like AOGO, transcending boundaries & paving a new era in finance and technology."
            },
            {
                icon: 'yes',
                content: 'literally a dog aogo a hat'
            },
        ]
    },
    {
        icon: ethIcon,
        winner: false,
        title: 'ETHEREUM',
        token: 'ETH',
        texts : [
            {
                icon: 'no',
                content: '12 transactions/second'
            },
            {
                icon: 'no',
                content: '2.1+ billon transactions'
            },
            {
                icon: 'no',
                content: 'chain not used for $AOGO'
            },
            {
                icon: 'no',
                content: 'not a dog'
            },{
                icon: 'no',
                content: 'no hat'
            }

        ]
    }
]
export default () => {
    return (
        <div className='flex flex-middle gap-60 p-t-36'>
            {
                list.map((item, index) => {
                    return <Item key={index} data={item}/>
                })
            }
        </div>
    )
}