import * as anchor from "@coral-xyz/anchor";
import { Program, BN, Wallet } from "@coral-xyz/anchor";
import { type Aogo, IDL } from "./aogo.ts";
import { PublicKey, Connection, SystemProgram, Transaction } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as spl from "@solana/spl-token";
import { Metaplex } from "@metaplex-foundation/js";
import { ASSOCIATED_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";

export class AogoClient {
    public program: Program<Aogo>;
    public metaplex: Metaplex;
    public endpoint: string;

    public static programId: string = "FpcnrJntp15VXsF1H2sw1k5d63PMtXHGBiKiUh3pqoqh";

    constructor(private connection: Connection, private wallet: Wallet) {
        const provider = new anchor.AnchorProvider(connection, this.wallet, anchor.AnchorProvider.defaultOptions());
        this.program = new anchor.Program(IDL, new PublicKey(AogoClient.programId), provider);
        this.metaplex = Metaplex.make(this.connection);
        this.endpoint = this.connection["_rpcEndpoint"];
    }

    findGlobalAccountPDA() {
        return PublicKey.findProgramAddressSync([Buffer.from("Global")], this.program.programId)[0];
    }

    findValultAccountPDA(mint: PublicKey) {
        return PublicKey.findProgramAddressSync([Buffer.from("Vault"), mint.toBuffer()], this.program.programId)[0];
    }

    findUserAccountPDA(user: PublicKey) {
        return PublicKey.findProgramAddressSync([Buffer.from("User"), user.toBuffer()], this.program.programId)[0];
    }

    async queryGlobalAccount() {
        return await this.program.account.globalAccount.fetchNullable(this.findGlobalAccountPDA());
    }

    async queryUserAccount(user: PublicKey) {
        return await this.program.account.userAccount.fetchNullable(this.findUserAccountPDA(user));
    }

    async getTokenBalance(tokenAccount: PublicKey) {
        try {
            return (await this.connection.getTokenAccountBalance(tokenAccount)).value;
        } catch (err) {
            return {};
        }
    }

    async signAndSendTransaction(transaction: Transaction) {
        const { blockhash } = await this.connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        if (this.wallet.publicKey) {
            transaction.feePayer = this.wallet.publicKey;
        }
        const signedTransaction = await this.wallet.signTransaction(transaction);
        return this.connection.sendRawTransaction(signedTransaction.serialize());
    }

    async claim(mint: PublicKey, amount: BN, signer: PublicKey, message: Uint8Array, signature: Uint8Array) {
        const vaultAccount = this.findValultAccountPDA(mint);
        const userTokenAccount = spl.getAssociatedTokenAddressSync(mint, this.wallet.publicKey);
        const claim = this.program.methods.claim(amount, Array.from(signature)).accounts({
            user: this.wallet.publicKey,
            globalAccount: this.findGlobalAccountPDA(),
            vaultAccount,
            userTokenAccount,
            userAccount: this.findUserAccountPDA(this.wallet.publicKey),
            tokenMint: mint,
            tokenProgram: TOKEN_PROGRAM_ID,
            ixSysvar: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
            associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        });
        const edInstruction = anchor.web3.Ed25519Program.createInstructionWithPublicKey({
            publicKey: signer.toBytes(),
            message,
            signature,
        });
        const modifyComputeUnits = anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({ units: 40_0000 });
        const transaction = new anchor.web3.Transaction().add(edInstruction, modifyComputeUnits, await claim.transaction());
        return await this.signAndSendTransaction(transaction);
    }
}
