import {message} from 'antd'
import { PublicKey }from '@solana/web3.js'

export const onCopyToText = (text:any, msg:string|undefined) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    message.success((msg||'Copied'))
  };

  export const addPoint = (address:string, len = 5) => {
    return address ? address.substr(0, len) + '...' + address.substr(address.length - len,) : ''
  }
  export const numFormat = function (num:any) {
    num = num.toString().split(".");  
    var arr = num[0].split("").reverse();  
    var res = [];
    for (var i = 0, len = arr.length; i < len; i++) {
      if (i % 3 === 0 && i !== 0) {
        res.push(",");   
      }
      res.push(arr[i]);
    }
    res.reverse(); 
  
    if (num[1]) {  
      return res.join("").concat("." + num[1]);
    } else {
      return res.join("");
    }
  }
  const howManyZero = (num:any) => {
    if (num > 1) {
      return 0
    } else {
      let zeronum = 0
      for (let i = 0; i <= 18; i++) {
        if (Number(num) >= Number(Math.pow(10, 0 - i))) {
          zeronum = i
          break;
        }
      }
      return zeronum - 1
    }
  }
  export const toFixed = (amount:any, num:any) => {
    if (Number(amount) < 1) {
      num <= howManyZero(amount) && (num = howManyZero(amount) + num)
    }
    return Math.floor(Number(amount) * Math.pow(10, num)) / Math.pow(10, num)
}


export const  isSolanaAddressValid = (address:string) => {
  try {
    const publicKey = new PublicKey(address);
    return publicKey.toBase58() === address;
  } catch (error) {
    return false;
  }
}

export const formatNumber = (n:any) => {
  n = n.toString();
  return n[1] ? n : "0" + n;
};
export const formatTime = (timestamp:string) => {
  if(!timestamp) {
    return '-'
  }
  let date = new Date(Number(timestamp) * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  return (
    [year, month, day].map(formatNumber).join("-") +
    " " +
    [hour, minute, second].map(formatNumber).join(":")
  );
};
export const formatYearDate = (timestamp) => {
  let date = new Date(Number(timestamp) * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    [year, month, day].map(formatNumber).join("-")
  );
};
export const formatDate = (timestamp) => {
  let date = new Date(Number(timestamp) * 1000);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    [month, day].map(formatNumber).join("-")
  );
};