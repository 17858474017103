/* eslint-disable import/no-anonymous-default-export */


import Home from '../pages/index'
import Air from '../pages/airdrop.jsx'

export default [
    {
        path: '/',
        component: <Home/>
    },
    {
        path: '/airdrop',
        component: <Air/>
    }
]