import Header from "./header"
import './index.scss'
import aogoIcon from '../assets/image/aogo.svg'
import arrowIcon from '../assets/image/arrow.svg'
import videoIcon from '../assets/image/video.svg'
import video2Icon from '../assets/image/video2.svg'
import video3Icon from '../assets/image/video3.svg'
import boxIcon from '../assets/image/box.svg'
import twitterIcon from '../assets/image/twitter.svg'
import mediumIcon from '../assets/image/medium.svg'
import discordIcon from '../assets/image/discord.svg'
import tgIcon from '../assets/image/tg.svg'
import dogIcon from '../assets/image/dog.svg'
import video1 from '../assets/image/AOGO-MV.webm'

import { Carousel } from 'antd';
import Best from "./best"
export default () => {
    return (
        <div className="w100">
            <Header/>
            <div className="slogan cf flex  flex-center flex-middle m-t-30">
                 WHAT IS&nbsp;<div className="aogo cg">AOGO</div> &nbsp;?
            </div>
            <p className="  ta cf main-text cf fz-28 ">
            AOGO ISN'T <span className="de fwb p-l-5 p-r-5">aogomeme JUST A DOG AOGO</span> A HAT ; IT'S A SYMBOL OF PROGRESS, FOR FUTURISTIC TRANSACTIONS, A BEACON FOR THOSE WHO THINK AHEAD. IT'S CLEAR THAT THE FUTURE BELONGS TO THOSE WHO EMBRACE INNOVATIONS LIKE AOGO, TRANSCENDING BOUNDARIES & PAVING A NEW ERA IN FINANCE AND TECHNOLOGY.
            </p>
            <div >
            <img src={aogoIcon} alt="" className="m-t-70" />
            </div>
            <div>
            <img src={arrowIcon} alt="" className="m-t-70" />
            </div>
            <div className="what w100 m-t-70">
                <p className="cf fz-56 fwb up">WHAT IS aogo? BE FR</p>
                <video src={video1} width={1280} controls></video>
            </div>

            <div className="serious w100 m-t-70 p-b-70">
                <p className="cf fz-56 fwb up">🙈BE FR FR I'M SO SERIOUS</p>
                <img src={video2Icon} alt="" />
            </div>

            <div className="audio w100 m-t-70 p-b-70">
                <p className="cf fz-56 fwb up">👂LISTEN</p>
                <audio src="" controls/>
            </div>

            <div className="w100 what m-t-70 p-b-70">
                <p className="cf fz-56 fwb up">GET AOGO THE BEST</p>
                <Best/>
            </div>
            <div className="w100 m-t-70 p-b-70">
                <p className="cf fz-56 fwb up">GET AOGO THE BEST</p>
                <img src={video3Icon} alt="" />
            </div>

            <Carousel autoplay>
                <div className="dog-item bgy">
                     <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgb">
                     <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgp">
                     <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgg">
                     <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgr">
                     <img src={dogIcon} alt="" />
                </div>

            </Carousel>

            <div className="footer p-t-70 p-b-40">
                 <div className="flex flex-center flex-middle gap-42">
                    <a href="https://twitter.com/aogomeme" target="_blank"><img src={twitterIcon} alt="" /></a>
                    {/* <a href="/"><img src={mediumIcon} alt="" /></a> */}
                    {/* <a href="/"><img src={discordIcon} alt="" /></a> */}
                    <a href="https://t.me/aogomememe" target="_blank"><img src={tgIcon} alt="" /></a>
                 </div>
                <p className="cf ta fz-20">Copyright © 2024 aogo. All Rights Reserve</p>
            </div>


            
        </div>
    )
}