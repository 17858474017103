
import Marquee from 'react-double-marquee';
import { NavLink } from 'react-router-dom';
export default () => {
    return (
        <div style={{
            width: '100%',
            whiteSpace: 'nowrap',
          }} className='p-t-12 p-b-12 header'>
            <Marquee direction="left" childMargin="30">
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>      
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>      
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>      
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>      
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>      
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>     
                <span className='m-r-80 cg'>
                🐶 i mean bro, it's literally a dog aogo a hat  
                </span>     

            </Marquee>

            <div className="flex flex-center flex-middle gap-80 m-t-20 p-b-6">
                 <NavLink to="/" className='normal-a nav-link'>AOGO HAT HOME</NavLink>
                 <NavLink to="/airdrop" className='normal-a nav-link'>AIRDROP</NavLink>
                 <a href="https://jup.ag/swap/SOL-Aogo_2PCG4XHNTndUSCNEaxzP4uKJUQDfAh6UJKhRoexN1oFN" target="_blank"  className='normal-a'>BUY AOGO US</a>
                 <a href="https://dexscreener.com/solana/emappwirvnq499pt39owqzqshtqviz6cecqkbvfu363i" target="_blank" className='normal-a'>CHART AOGO US</a>
                 
                                   
            </div>
        </div>
    )
}