import Header from "./header.jsx"
import './index.scss'
import twitterIcon from '../assets/image/twitter.svg'
import banner from '../assets/image/airdrop/banner.svg'
import mask from '../assets/image/airdrop/mask.svg'
import dog from '../assets/image/airdrop/dog.svg'
import tgIcon from '../assets/image/tg.svg'
import dogIcon from '../assets/image/dog.svg'
import disconnectIcon from '../assets/image/airdrop/disconnect.svg'
import video1 from '../assets/image/AOGO-MV.webm'
import base58 from "bs58";
import { LoadingOutlined } from '@ant-design/icons';
import * as p_anchor from '@project-serum/anchor';
import { IDL } from '../http/aogo.ts'
import * as anchor from "@coral-xyz/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { Button, Carousel, Skeleton, Spin, message as messageAnt } from 'antd';
import { useCallback, useEffect, useState } from "react"
import { get } from '../http/index'
import { useLinkClickHandler } from "react-router-dom"
import { Metaplex } from "@metaplex-foundation/js"
import { Metadata } from "@metaplex-foundation/mpl-token-metadata"
import * as bs58 from "bs58";
import * as spl from "@solana/spl-token";
import { BN } from "@coral-xyz/anchor";
import { PublicKey, SystemProgram, } from "@solana/web3.js"
import { AogoClient } from "../http/client.ts";
import { numFormat } from "../http/util.ts"
import { ASSOCIATED_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
export default () => {
    const wallet = useWallet();
    const { connection } = useConnection();
    const { connected, publicKey, disconnect } = useWallet();
    const [airdropAmount, setAirdropAmount] = useState(0)
    const [refresh, setRefresh] = useState(0)
    // const [claimparams, setClaimparams] = useState({})
    const { setVisible } = useWalletModal();
    const [loading, setLoading] = useState(false)
    const [searching, setSearching] = useState(false)
    const [claimlog, setClaimlog] = useState([])
    const connectToPhantom = useCallback(async () => {
        try {
            // 创建一个Phantom钱包适配器实例
            const phantomAdapter = new PhantomWalletAdapter();
            
            // 确保适配器已就绪
            // await phantomAdapter.ready();

            // 使用适配器的名称设置wallet的适配器
            wallet.select(phantomAdapter.name);

            // 连接钱包
            await wallet.connect();
        } catch (error) {
            console.error('连接Phantom钱包失败', error);
        }
    }, [wallet]) 
    useEffect(() => {
        (async () => {
            if (publicKey && connected) {
                setSearching(true)
                try {
                    let claimParam = await get(`/api/user/claimParams?address=${publicKey.toBase58()}`)
                    // setClaimparams(claimParam.data)
                    setAirdropAmount(claimParam.data.amount/1000000)
                } catch {
                    setAirdropAmount(0)
                }
                try {
                    let logs = await get(`/api/user/claimLogs?address=${publicKey.toBase58()}`)
                    console.log(logs.data)
                    setClaimlog(logs.data)
                } catch {
                    setClaimlog([])
                }
                setSearching(false)
            } else {
                setAirdropAmount(0)
            }
        })()
    }, [publicKey, refresh])
    const toClaim = useCallback(async() => {
        if (wallet.connected && wallet.publicKey && wallet.signTransaction && connection) {
            setLoading(true)
            let {data} = await get(`/api/user/claimParams?address=${wallet.publicKey.toBase58()}`)
           
            const { mint, amount, message, signature, signer } = data;
         try {
            const client = new AogoClient(connection, wallet);
                const result = await client.claim(new PublicKey(mint), new BN(amount), new PublicKey(signer), base58.decode(message), base58.decode(signature));
                console.log("ts", result);
              if(result) {
                setTimeout(()=>{
                    messageAnt.success('Claim successful')
                    setRefresh(refresh+1)
                    setLoading(false)
                }, 4000)
              }
         } catch (err) {
            console.log(err)
            messageAnt.error('Claim failed')
            setLoading(false)
        }
        }

    }, [connection, wallet.publicKey, wallet.sendTransaction, wallet, refresh])
    const handleConnect = async () => {
        try {
            // setVisible(true);
            connectToPhantom()
        } catch (error) {
            console.log(error);
        }
    };
    const handleDisconnect = async () => {
        try {
            await disconnect();
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="w100">
            <Header />
            <div className="flex w100 flex-middle">
                <img src={banner} alt="" className="w100" />
            </div>
            <div className="flex flex-middle p-b-70">
                <div className="left-part">
                    <div className="slogan cf flex flex-column flex-start flex-middle m-t-30">
                        <span>AOGO AIRDROP FOR</span>
                        <span className="flex flex-center"> <div className="aogo cg">$WEN</div> &nbsp;HOLDERS </span>
                    </div>
                    <div className=" tl cf cf fz-28 ">
                        AOGO ISN'T <span className="de fwb p-l-5 p-r-5">aogomeme JUST A DOG AOGO</span> A HAT ; IT'S A SYMBOL OF PROGRESS, FOR FUTURISTIC TRANSACTIONS, A BEACON FOR THOSE WHO THINK AHEAD.
                        <div className="cg underline up">check you’re eligible or not below! </div>
                    </div>

                </div>
                <div className="flex flex-column flex-center right-part">
                    <img src={dog} alt="" />
                    <div className="up cf m-t-20" style={{ "font-style": "italic" }}>Claim deadline</div>
                    <div className="deadline cf bgc lh-30 p-l-20 p-r-20 m-t-15">2024-04-01 00:00:00</div>
                </div>
            </div>
            <div className="airdrop  p-t-20 p-b-50">
                <div className="w100 flex flex flex-wrap flex-middle">
                    <div className="airdrop-step p-10 p-t-40 gap-20 flex flex-column flex-start">
                        <span className="cf fz-24 tl">1. connect your phantom wallet</span>
                        {
                            connected ?
                                <div className="airdrop-box flex flex-center flex-middle cg fz-24 fwb flex flex-center flex-middle gap-30" >
                                    <span className="up cy">{publicKey?.toBase58().slice(0, 6) + '...' + publicKey?.toBase58().slice(-4)}</span>
                                    <img src={disconnectIcon} alt="" className="pointer" onClick={handleDisconnect} />
                                </div>
                                :
                                <div className="airdrop-box flex flex-center flex-middle cg fz-24 fwb pointer" onClick={handleConnect}>
                                    <span className="up">Connect wallet</span>
                                </div>
                        }
                    </div>
                    <div className="airdrop-step p-10 p-t-40 gap-20 flex flex-column flex-start">
                        <span className="cf fz-24 tl">2. Review your reward</span>
                        <div className="airdrop-box flex flex-center flex-middle cy fz-24 fwb flex flex-column">
                            {
                                searching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}  size="small" />:<>
                                <div className="up">{numFormat(airdropAmount)} $AOGO</div>
                            {
                                (airdropAmount <= 0 && connected) && <div className="fz-14 cr m-t-10">You're not eligible</div>
                            }
                                </>
                            }
                            

                        </div>
                    </div>
                </div>
                <div className="w100 flex flex flex-wrap flex-middle">
                    <div className="airdrop-step p-10 p-t-40 gap-20 flex flex-column flex-start">
                        <span className="cf fz-24 tl">3. Claim your reward</span>
                        <Button loading={loading } className={"airdrop-box flex flex-center flex-middle cg fz-24 fwb pointer " + ((searching || airdropAmount <= 0 || claimlog.length)? 'disabled' : '')}
                         onClick={!(airdropAmount <= 0 || claimlog.length) ? toClaim:()=>{return false}}>
                            <span className={"up " + ((airdropAmount <= 0 || claimlog.length) ? 'op-02' : '')}>Claim{(claimlog.length?'ed':'')}</span>
                        </Button>
                    </div>
                    <div className="airdrop-step p-10 p-t-40 gap-20 flex flex-column flex-start">
                        <span className="cf fz-24 tl">4. Follow $aogo in twitter</span>
                        <a href="https://twitter.com/aogomeme" target="_blank">
                            <div className="airdrop-box flex flex-center flex-middle cg fz-24 fwb pointer">
                                <span className="up">Follow aogo X</span>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div className="flex w100 flex-middle">
                <img src={mask} alt="" className="w100" />
            </div>


            <div className="serious w100 m-t-70 m-b-70">
                <p className="cf fz-56 fwb up">WHAT IS aogo? BE FR</p>
                <video src={video1} width={1280} controls></video>
            </div>




            <Carousel autoplay>
                <div className="dog-item bgy">
                    <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgb">
                    <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgp">
                    <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgg">
                    <img src={dogIcon} alt="" />
                </div>
                <div className="dog-item bgr">
                    <img src={dogIcon} alt="" />
                </div>

            </Carousel>

            <div className="footer p-t-70 p-b-40">
                <div className="flex flex-center flex-middle gap-42">
                    <a href="https://twitter.com/aogomeme" target="_blank"><img src={twitterIcon} alt="" /></a>
                    {/* <a href="/"><img src={mediumIcon} alt="" /></a> */}
                    {/* <a href="/"><img src={discordIcon} alt="" /></a> */}
                    <a href="https://t.me/aogomememe" target="_blank"><img src={tgIcon} alt="" /></a>
                </div>
                <p className="cf ta fz-20">Copyright © 2024 aogo. All Rights Reserve</p>
            </div>



        </div>
    )
}